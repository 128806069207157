import { Build } from '@/components/Build';

import { Learn } from '@/components/Learn';
import { Milestones } from '@/components/Milestones';
import { FC, useState } from 'react';
import styles from './home.module.scss';
// import allLight from '@/assets/images/products/allLight.svg';
import { StarProtocolIntroducetion } from '@/components/StarProtocolIntroducetion';
import { Partner } from '@/components/Partner';
import { DigitalRealms } from '@/components/DigitalRealms';
import { Footer } from '@/components/Footer';
import FlipCard from '@/components/FlipCard';

export const Home: FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const disVisibility = () => {
    setIsVisible(false);
  };

  const btnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    // setIsVisible(!isVisible);
  };

  return (
    <div className={styles.home}>
      <div className={styles.leftRenderWrapper}>
        {/* <div className={styles.leftRender}></div>
        <div className={styles.rightRender}></div> */}
      </div>
      <div className={styles.homeInner}>
        {/* <img src={allLight} className={styles.allLight} /> */}

        <div onClick={disVisibility} className={`${styles.msgBox} ${isVisible ? `${styles.visible}` : `${styles.hidden}`}`}>
          <div className={styles.msgContent}>
            <h2 className={styles.msgTitle}>Urgent Notification:</h2>
            <p>
              Our official X and Discord accounts were misappropriated by a project which is not affiliated with us.
            </p>
            <p>
              Star Protocol and related products continue to operate as usual.
            </p>
            <p>
              To retain eligibility for airdrops, avoid migrating the domain name to other projects.
            </p>
            Please follow our updated Twitter and Discord for the latest info. Thank you for your continued trust and support.

          </div>
          <div className={styles.msgBar} onClick={btnClick} >
            <a href='https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Eelon&region=follow_link&screen_name=starprotocol_hq' target={'_blank'} rel="noreferrer" className={styles.msgBtn}>Follow X.com</a>
            <a href="https://discord.com/invite/ZDGpWaSJ3u" target={'_blank'} rel="noreferrer" className={styles.msgBtn}>Join Discord</a>
          </div>
        </div>


        <Learn></Learn>
        <div onClick={toggleVisibility} className={styles.msgIcon}>Message from Star Protocol Team </div>

        {/* <Build /> */}
        <Milestones />
        <StarProtocolIntroducetion />
        {/* <FlipCard /> */}
        <Partner />
        <div className={styles.digitalWrapper}>
          <DigitalRealms />
        </div>

        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
