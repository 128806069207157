import React from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const Screen = () => {
  const isMobile = isMobileDevice();

  return (
    <div className="screen">
      {isMobile ? (
        <div className="mobile-div">

          <div className="headMb">
            <p className="mainTitle">AIRDROP PREMINT FROM AUGUST 2 TO AUGUST 30</p>

            <div className="lineText">AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT </div>

            <div className="mainStep">
              <div className="stepDIv">
                <div className="">AUGUST 2024</div>
                <div className="">GENESIS LAUNCH</div>
                <p className="">999 Stars of mythical power from space</p>
                <div className="" />
                <NavLink to="/Claim">
                  <div className="stepBtn">RSVP Now</div>

                </NavLink>
              </div>
              <div className="stepDIv">
                <div className="">AUGUST 2024</div>
                <div className="">GENESIS LAUNCH</div>
                <p className="">999 Stars of mythical power from space</p>
                <div className="" />
                {/* <NavLink to="/Claim"> */}
                  <div className="stepBtn disBtn">Lucky Draw</div>

                {/* </NavLink> */}
              </div>
              <div className="stepDIv">

              </div>
              <div className="stepDIv">

              </div>
            </div>
          </div>

        </div>
      ) : (
        <div className="div">
          <header className="header">
            <div className="frame">
            </div>
          </header>
          <div className="overlap">
            <div className="frame-2">
              <div className="frame-3">
                <div className="text-wrapper-3">Domain as a Service</div>
                <p className="p">Partner with Star for omnichain name services</p>
              </div>
            </div>
            <div className="rectangle" />
            <div className="rectangle-2" />
            <div className="rectangle-3" />
            <div className="rectangle-4" />
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="group">


            </div>
            <div className="rectangle-7" />
            <div className="text-wrapper-4">AUGUST 2024</div>
            <div className="text-wrapper-5">GENESIS LAUNCH</div>
            <p className="text-wrapper-6">999 Stars of mythical power from space</p>
            <div className="rectangle-8" />
            <NavLink to="/Claim">
              <div className="text-wrapper-7">RSVP Now</div>

            </NavLink>
            <div className="text-wrapper-8">AUGUST 2024</div>
            <div className="text-wrapper-9">GENESIS LAUNCH</div>
            <p className="text-wrapper-10">999 Stars of mythical power from space.</p>
            <div className="rectangle-9" />
            <div className="text-wrapper-11">Lucky Draw</div>
            <div className="element-cards">
              <div className="overlap-3">
                <img
                  className="card-back"
                  alt="Card back"
                  src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/card-back.svg"
                />
                <img
                  className="card-back-2"
                  alt="Card back"
                  src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/card-back-1.svg"
                />
                <img
                  className="card-back-3"
                  alt="Card back"
                  src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/card-back-2.svg"
                />
                <img
                  className="card-back-4"
                  alt="Card back"
                  src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/card-back-3.svg"
                />
                <img
                  className="card-back-5"
                  alt="Card back"
                  src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/card-back-4.svg"
                />
              </div>
            </div>
          </div>
          <div className="overlap-4">
            <div className="rectangle-10">
              <p className="text-wrapper-12">AIRDROP PREMINT FROM AUGUST 2 TO AUGUST 30</p>

              <div className="rectangle-11" />
              <div className="text-wrapper-13">AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT AIRDROP ANNOUNCEMENT </div>


            </div>
          </div>
          <div className="text-wrapper-19">Star Protocol Airdrop</div>
          <img
            className="kabi"
            alt="Kabi"
            src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/kabi.png"
          />
          <img
            className="kabi-2"
            alt="Kabi"
            src="https://cdn.animaapp.com/projects/66bf07bec2a2e2bf894f542a/releases/66d17b1e425365b2e1e1d420/img/kabi-1.png"
          />
        </div>
      )}

    </div>
  );
};
