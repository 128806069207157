import cn from 'classnames';
import React from 'react';
// import titleBg from '@/assets/images/titleBG.png';
import styles from './Learn.module.scss';
// import title1 from '@/assets/images/title1.svg';
import moon from '@/assets/images/vvv.png';
import { Button } from '../Button';
import { Platform } from '../Platform';
import useWindowSize from '../../hooks/useWindowSize';


interface LearnProps {
  className?: string;
}

export function Learn(props: LearnProps) {
  const { className } = props;
  const size = useWindowSize();

  return (
    <div className={cn(styles.Learn, className)}>
      <div className={styles.titleWrapperBox}>
        <div className={styles.titleWrapper}>
          {size[0] > 768 ? (
            <>
              {' '}
              <p>
                <span className={styles.one}>OMNI DID</span>
                {/* <img src={ONE_IDENTITY} alt="" className={styles.one} /> */}
                <div className={styles.moonBox}>{/* <img src={moon} alt="" className={styles.moon} /> */}</div>
                {/* <img src={FOR} alt="" className={styles.for} /> */}
                <span className={styles.for}>FOR</span>
              </p>
              {/* <img src={All_CHAINS} alt="" className={styles.chains} /> */}
              <span className={styles.chains}>All OBJECTS</span>
            </>
          ) : (
            <>
              <span className={styles.one}>OMNI DID</span>
              <p className={styles.mobile_title}>
                <span className={styles.for}>FOR </span>
                <span className={styles.chains}>ALL OBJECTS</span>
              </p>
              <div className={styles.moonBox}>{/* <img src={moon} alt="" className={styles.moon} /> */}</div>
            </>
          )}
        </div>
        <div className={styles.titleWrapper2}>
          {size[0] > 768 ? (
            <>
              {' '}
              <p>
                <span className={styles.one}>OMNI DID</span>
                {/* <img src={ONE_IDENTITY} alt="" className={styles.one} /> */}
                <div className={styles.moonBox}>
                  <img src={moon} alt="" className={styles.moon} />
                </div>
                {/* <img src={FOR} alt="" className={styles.for} /> */}
                <span className={styles.for}>FOR</span>
              </p>
              {/* <img src={All_CHAINS} alt="" className={styles.chains} /> */}
              <span className={styles.chains}>All OBJECTS</span>
            </>
          ) : (
            <>
              <span className={styles.one}>OMNI DID</span>
              <p className={styles.mobile_title}>
                <span className={styles.for}>FOR </span>
                <span className={styles.chains}>All OBJECTS</span>
              </p>
              <div className={styles.moonBox}>
                <img src={moon} alt="" className={styles.moon} />
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.desc}>
        {size[0] > 768 ? (
          <>
            {' '}
            Explore the frontier of DePIN and AI with us,
            <div></div>
            where every object and individual is part of a larger cosmos.
          </>
        ) : (
          <>
            Explore the frontier of DePIN and AI with us, <div></div> <div></div>{' '}
            where every object and individual is part of a larger cosmos.
          </>
        )}
      </div>
      {/* <div className={styles.btnBox}>
        <Button />
      </div> */}
      {/* <div className={styles.text}>
        {size[0] > 768 ? (
          <>ENABLING SEAMLESS INTEGRATION FOR COMPANIES WITH OMNICHAIN IDENTITY</>
        ) : (
          <>
            ENABLING SEAMLESS INTEGRATION FOR<div></div> COMPANIES WITH OMNICHAIN IDENTITY
          </>
        )}
      </div> */}
      <div className={styles.footer} id="products">
        <Platform />
      </div>
    </div>
  );
}
