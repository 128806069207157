import { createBrowserRouter } from 'react-router-dom';
import Layout from '@/layout';
import { Home } from '@/views';
import { Cards } from '@/components/PartnerCard';
import { ClaimPage } from '@/components/ClaimPage';
import { Screen } from '@/components/ClaimPageMain';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <div>404 Not Found</div>,
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/login',
        element: <div>login</div>,
      },
      {
        path: '/partner',
        element: <Cards />,
      },
      {
        path: '/Claim',
        // eslint-disable-next-line react/jsx-no-undef
        element: <ClaimPage />,
      },
      {
        path: '/ClaimScreen',
        // eslint-disable-next-line react/jsx-no-undef
        element: <Screen />,
      },
    ],
  },
]);

export default router;
