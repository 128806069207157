import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import starLogo from '@/assets/images/starLogo.png';
import bread from '@/assets/images/products/bread.svg';
import styles from './Header.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { Menus } from '../Menus';
import toast from 'react-hot-toast';

const LinkStyle = {
  color: '#fff',
};

interface HeaderProps {
  className?: string;
}

export function HeaderCover(props: HeaderProps) {
  const { className } = props;
  const [show, setShow] = useState(false);

  const location = useLocation();
  const [hiddrenAnchor, setHiddrenAnchor] = useState(false);

  useEffect(() => {
    if (location.pathname === '/partner') {
      setHiddrenAnchor(true);
    } else {
      setHiddrenAnchor(false);
    }
  }, [location?.pathname]);

  function onHiddrenMenus() {
    setShow(false);
  }

  return (
    <div className={cn(styles.HeaderCover, className)}>
      <div className={styles.innerCover}>
        <NavLink to={'/'}>
          <div className={styles.themeLogo}>
            {' '}
            <img src={starLogo} />
          </div>
        </NavLink>

        <div className={styles.right}>
          {/* <div className={styles.a} onClick={() => toast('coming soon')}>
            Learn
          </div> */}
          {/* <a href="https://forms.gle/u82vuoVnhjAnhSif9" target={'_blank'} rel="noreferrer">
            Build
          </a> */}
          <NavLink to="/ClaimScreen">
            {!hiddrenAnchor && <span className='linkMenu' style={LinkStyle}>Claim</span>}
          </NavLink>
          {!hiddrenAnchor && <a href="#products">Products</a>}
          {!hiddrenAnchor && <a href="#solutions">Solutions</a>}

          <NavLink to="/partner">Partnership</NavLink>
          <a className={styles.launchApp} href="https://sns.so/">Launch App</a>

        </div>

        <div className={cn(styles.mobile_header)}>
          <NavLink to={'/'}>
            <img src={starLogo} className={styles.logo_mobile} />
          </NavLink>
          <div className={styles.breadBox}>
            <div className={styles.bread} onClick={() => setShow((x) => !x)}>
              <img src={bread} alt="" className={styles.bread} />
            </div>
            <div className={cn(styles.menus, { [styles.show]: show })}>
              <Menus onHiddrenMenus={onHiddrenMenus} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
