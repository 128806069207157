import axios from 'axios';

// Base URL
const BASE_URL = 'https://star.npmcow.com';

// 获取/生成推荐码
// export const getReferral = async (address) => {
//     try {
//         const response = await axios.post(`${BASE_URL}/getReferral`, { address });
//         return response.data;
//     } catch (error) {
//         console.error('Error getting referral:', error);
//         throw error;
//     }
// };

// 保存签名
// export const saveSignature = async (address, signature) => {
//     try {
//         const response = await axios.post(`${BASE_URL}/saveSignature`, { address, signature });
//         return response.data;
//     } catch (error) {
//         console.error('Error saving signature:', error);
//         throw error;
//     }
// };



// 检查域名
export const getDomain = async (address: any) => {
    try {
        const response = await axios.post(`${BASE_URL}/getDomain`, { address });
        return response.data;
    } catch (error) {
        console.error('Error checking domain:', error);
        throw error;
    }
};

export const checkEligibility = async(address: any) => {
    try{
        const response = await axios.get(`https://dry-surf-b758.pengjuandu9588.workers.dev/?address=${address}`);
        return response.data;
    } catch(error){
        console.error('Error checking account domain:', error);
        throw error;    
    }
}

// 邀请人绑定接口
// export const addInvitedUser = async (referralCode, invitedAddress) => {
//     try {
//         const response = await axios.post(`${BASE_URL}/addInvitedUser`, { referralCode, invitedAddress });
//         return response.data;
//     } catch (error) {
//         console.error('Error adding invited user:', error);
//         throw error;
//     }
// };
