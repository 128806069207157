import '@rainbow-me/rainbowkit/styles.css';

import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

// const configureChainsConfig = configureChains(
//   [mainnet, goerli, optimism, avalancheFuji, zkSync, base, linea, zkSyncTestnet, optimismGoerli],
//   [publicProvider()]
// );

// const { chains, publicClient } = configureChainsConfig;

// const client = createConfig({
//   autoConnect: true,
//   connectors: [new InjectedConnector({ chains, options: { name: 'MetaMask' } })],
//   publicClient,
// });

const client = getDefaultConfig({
  appName: 'Star Homepage',
  projectId: '62a6af6b5b5bf7638f5cde0aa06a723c',
  chains: [mainnet, polygon, optimism, arbitrum, base],
  ssr: false, // If your dApp uses server side rendering (SSR)
});
export { client };
