import React, {useRef, useState} from 'react';
import styles from './index.module.css';
import {Canvas, useFrame, useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import useWindowSize from "@/hooks/useWindowSize";

interface Step {
    navBar: string;
    title:React.ReactNode ;
    content: React.ReactNode;
}

function Model() {
    const gltf = useLoader(GLTFLoader, '/pet.glb');
    const modelRef = useRef<THREE.Group>(null!);
    const maxRotationX = Math.PI / 4; 
    const maxRotationY = Math.PI / 3; 
    const rotationSpeed = 0.2;

    
    useFrame((state) => {
        const {mouse} = state;
        if (modelRef.current) {
            
            modelRef.current.rotation.y = Math.max(
                -maxRotationY,
                Math.min(maxRotationY, mouse.x * Math.PI * rotationSpeed)
            );

            
            // modelRef.current.rotation.x = Math.max(
            //     -maxRotationX,
            //     Math.min(maxRotationX, -mouse.y * Math.PI * rotationSpeed)
            // );
        }
    });
    return <primitive ref={modelRef} object={gltf.scene}/>;
}


const Steps: React.FC<{ steps: Step[] }> = ({steps}) => {
    const [currentStep, setCurrentStep] = useState<number>(0);

    const size = useWindowSize();
    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };

    return (
        <div className={styles.menuContent}>
            <div className={styles.petBox}>
                <Canvas>
                    <ambientLight intensity={0.5}/>
                    <pointLight position={[10, 10, 10]}/>
                    <Model/>
                </Canvas>
            </div>
            {size[0] >= 500 && <div className={styles.menuSteps}>
        <span
            className={styles.lineStep}
        ></span>
                {steps.map((step, index) => (
                    <div
                        key={index}
                        style={{
                            padding: '10px 0 10px 25px',
                            backgroundColor: index === currentStep ? 'rgb(211 211 211 / 64%)' : '#444',
                            marginTop: '10px',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            fontSize: "12px"
                        }}
                        onClick={() => setCurrentStep(index)}
                    >
                        {step.navBar}
                    </div>
                ))}
            </div>}
            <div className={styles.menuRow}>
                <div className={styles.titleBackground}>
                    <h2>{steps[currentStep].title}</h2>
                </div>
                <p>{steps[currentStep].content}</p>
                <div className={styles.btnBar}>
                    <button className={styles.btnSteps} onClick={handlePrev} disabled={currentStep === 0}
                            style={{color: 'white'}}>
                        <div className={styles.arrowLeft}></div>
                        Back
                    </button>
                    <button className={styles.btnSteps} onClick={handleNext} disabled={currentStep === steps.length - 1}
                            style={{color: 'white'}}>Proceed <div className={styles.arrowRight}></div></button>
                </div>
            </div>
        </div>
    );
};

export default Steps;
