import styled from 'styled-components';
import { getURL } from '@/tool/getVideoURL';
import { useConnect, useDisconnect } from 'wagmi'

import * as THREE from 'three';
// import arrow from "../../assets/linea/arrow.png";
import { partaner } from './list';
import footerBg from '@/assets/images/footerBg.svg';
import pet from '@/assets/images/pet.png';
import cn from 'classnames';
import styles from './index.module.css';
import React, { useEffect, useReducer, useState, useRef, useCallback, ChangeEvent } from 'react';
import { Footer } from '../Footer';
import Steps from './steps';
import { injected } from 'wagmi/connectors'

import yellowStar from '../../assets/images/yellow_star.png';
// import { IoWallet } from "react-icons/io5";

import { useFrame, Canvas } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { checkEligibility, getDomain } from "../../api/index"
import { useAccount } from 'wagmi';


// import useWindowSize from '@/hooks/useWindowSize';
const LineaCard = styled.div`
  overflow:hidden;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  // background: #008DDAdb;
  position: relative;
  overflow: hidden;

  main {
    margin: 0 auto;
    padding: 0 140px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 40px;
    margin-bottom: 40px;
    gap: 38px 20px;
    box-sizing: border-box;
    position: relative;
    height: fit-content;

    @media screen and (max-width: 1198px) {
      width: 890px;
      padding: 0;
      padding-top: 40px;
    }
    @media screen and (max-width: 1180px) {
      gap: 30px 16px;
      margin-bottom: 39px;
    }

    @media screen and (max-width: 890px) {
      width: 700px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;
const Title = styled.div`
  font-size: 64px;
  font-family: 'GT-Walsheim-Bold-Trial';
  color: #fff;
  white-space: pre-line;
  text-align: center;
  padding-top: 121px;
  margin-bottom: 40px;

  @media screen and (max-width: 1280px) {
    font-size: 5.3rem;
    padding-top: 10rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    /* padding-top: 32px; */
    margin-bottom: 32px;
    padding-top: 74px;
  }
`;
const Inner = styled.div`
  width: 1280px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Button = styled.button`
  margin: 0 auto;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-family: 'GT-Walsheim-Bold-Trial';
  border-radius: 8px;
  background: linear-gradient(90deg, #6806e4 3.8%, #47a7ff 91.66%);
  border: none;

  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, #6806e4 3.8%, #47a7ff 91.66%);
    color: #fff;
  }

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
`;

const Butn = styled.div``;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const btns = [
  { id: 1, name: 'ALL', active: true },
  { id: 2, name: 'Wallet', active: false },
  { id: 3, name: 'Social', active: false },
  { id: 4, name: 'Game', active: false },
  { id: 5, name: 'NFT', active: false },
  { id: 6, name: 'Defi', active: false },
  { id: 7, name: 'Bridge', active: false },
  { id: 8, name: 'Infrastructure', active: false },
  { id: 9, name: 'Tool', active: false },
  { id: 10, name: 'DAO', active: false },
];




interface ActionState {
  type: string;
  payload: number | string;
}
export function ClaimPage() {
  const { disconnect } = useDisconnect()
  const { connect } = useConnect()
  const [videodata, setVideoData] = useState('');
  const [domainInfo, setDomainInfo] = useState(null);
  const { address, isConnected } = useAccount()
  const [isEligible, setIsEligible] = useState<boolean | null>(false);
  const [isSubmit, setIsSubmit] = useState<boolean | null>(false);

  const [inputValue, setInputValue] = useState("")

  const getDomainSync = async (recoveredAddress: any) => {
    try {
      const response = await getDomain(recoveredAddress);
      setDomainInfo(response.body);
      console.log('Domain info:', response.body);
    } catch (error) {
      console.error('Error checking domain:', error);
    }
  }

  useEffect(() => {
    const videos = document.getElementsByTagName('video');
    getURL()
      .then((url: any) => {
        setVideoData(url);
        setTimeout(() => {
          if (videos && videos.length > 0) {
            videos[0].play();
          }
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [btnss, dispatch] = useReducer(reducer, btns);
  const [logos, LogoDispatch] = useReducer(reducer, JSON.parse(JSON.stringify(partaner)));

  function reducer(state: any, action: ActionState) {
    if (action.type === 'select') {
      const arr = JSON.parse(JSON.stringify(state));
      arr.forEach((v: any) => {
        if (v.id === action.payload) {
          v.active = true;
        } else {
          v.active = false;
        }
      });
      return arr;
    }

    if (action.type === 'logo') {
      if (typeof action.payload === 'string' && action.payload.trim().toLocaleLowerCase() === 'all') {
        return partaner;
      }
      return partaner.filter((v) => {
        if (
          typeof action.payload === 'string' &&
          v.category.trim().toLocaleLowerCase() === action.payload.trim().toLocaleLowerCase()
        ) {
          return true;
        }
        return false;
      });
    }
  }

  function onSelected(id: number, name: string) {
    dispatch({ type: 'select', payload: id });
    onChooseSameType(name);
  }

  function onChooseSameType(type: string) {
    LogoDispatch({ type: 'logo', payload: type });
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }


  const checkEligibilityWithoutAccount = async (address: any) => {
    try {
      const response = await checkEligibility(address);
      const result = response.data.totalPoints !== 0;
      setIsSubmit(true)
      setIsEligible(result);
    } catch (error) {
      console.error('Error checking eligibility:', error);
      setIsEligible(false);
    }
  }

  useEffect(() => {
    const checkEli = async () => {
      try {
        const response = await checkEligibility(address);
        const result = response.data.totalPoints !== 0;
        setIsEligible(result);
      } catch (error) {
        console.error('Error checking eligibility:', error);
        setIsEligible(false);
      }
    };
    checkEli();
  }, [address]);


  const Container = styled.div`
display: flex;
    width: 240px;
    height: 40px;
    align-items: center;
    background-color: transparent;
    color: white;
    padding: 8px;
    border-radius: 6px;
    padding-top: 10px;
    padding-left: 12px;
    position: absolute;
    top: 5px;
    right: -10px;
`;

//   const StyledLock = styled(IoWallet)`
// color: #ef4444;
// margin-right: 8px;
// `;

  const AddressText = styled.span`

font-family: monospace;
font-size: 15px;
`;

  function shortenAddress(address: any, chars = 9): string {
    if (!address) return '';
    if (address.length < chars * 2) return address;
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  }

  const StyledAddressDisplay = (props: any) => {
    return (
      <Container>
        {/* <StyledLock size={20} /> */}
        <AddressText>{props.address}</AddressText>
      </Container>
    );
  };

  const EligibleText = <div style={{ marginTop: '40px' }}>
    <div className={styles.isEligibleText_0}>You are eligible</div>
    <div className={styles.isEligibleText_1}>Congratulations,you are eligible for the Star Drop !</div>
    <button className={styles.registerButton } onClick={() => console.log('111')}>Register</button>
  </div>

  const notEligibleText = <div style={{ marginTop: '45px' }}>
    <div className={styles.notEligibleText_0}>Address is not eligible</div>
    <div className={styles.notEligibleText_1}>Sorry, you are not eligible for the Star Drop.</div>
  </div>

  const inputWidget = <div>
    <input className={styles.input} onChange={onInputChange}></input>
    <button className={styles.walletBtn} onClick={() => checkEligibilityWithoutAccount(inputValue)}>Check Eligibility</button>
  </div>


  const board = <div>{inputWidget}{isEligible ? EligibleText : notEligibleText} </div>
  const steps = [
    {
      navBar: "1 Welcome",
      title: 'Welcome to our Airdrop',
      content: <div>
        <p>Star Protocol is decentralising further and supporting stakeholders through an airdrop. You may be eligible if you 've interacted with Star Protocol chains, applications, or community in the past. Check your eligibility and claim your Star now.</p>
      </div>
    },
    {
      navBar: "2 Connect wallet",
      title: <div className={styles.titleBar}>Connect wallet</div>,
      content: <div style={{zIndex:'-3'}}>
        <p>Choose your wallet type.Authorize the connection.Once connected, you re ready to proceed with the next steps.Thank you for participating!</p>
        {/* <button onClick={() => disconnect()}> */}

        <p> If you encounter any issues, feel free to contact our support team.</p>
        {isConnected ? <div className={styles.btnOuter}><button className={styles.btnDisconnect} onClick={() => {disconnect()}}>Disconnect</button></div>
          : <div className={styles.btnOuter}><ConnectButton showBalance={false} chainStatus="none" label="Connect Wallet"></ConnectButton> </div>}
        {/* <button className={styles.walletBtn}>Connect Wallet</button> */}
        {/* <div>{isConnected ? }</div> */}
        {isConnected ? StyledAddressDisplay({ address: shortenAddress(address) }) : StyledAddressDisplay({ address: null })}
        <br></br>
      </div>,
    },
    {
      navBar: "3 Verify Eligibility",
      title: 'Verify Eligibility',
      content: <div style={{zIndex:'-3'}}>
        <p>Please make sure you have read and agreed to our Airdrop rules and terms. Only participants who comply with the specified requirements are eligible to claim tokens.</p>
        {/* <div className={styles.inputOuter}> */}
        <div className={styles.eligibilityText}>
          {isConnected ? <div>
            {isEligible ? EligibleText : notEligibleText}
          </div>
            : 
            // <div>{isSubmit ? board : inputWidget}
            // </div>
            <div>Please connect your wallet first</div>
          }
        </div>
      </div>,
    },
    {
      navBar: "4 Claim",
      title: 'Claim',
      content: <div>
        <p>Get lost in our huge, bustling community of crypto enthusiasts. </p>
        <p>It’s the coolest place to hang out, and learn new tricks in the trade. </p>
        <p>The latest and hottest of the digital world, right at your fingertips. You don’t want to miss out‚ trust us! </p>
        <button className={styles.walletBtn}>Claim Your Token</button>
      </div>,
    }
  ];

  return (
    <LineaCard>
      <div className={styles.PartnerWrapper}>
        <Inner>
          <Title className={styles.titleStep}>{`Get your airdrops and Star NFT now!`}
          </Title>

          <Title className={styles.titleStepTrue}>{`Get your airdrops and Star NFT now!`}
            {/*<button onClick={() => getDomainSync('0xdf1d00bc5bea1e716a3018bcea53e32ff4b76d9b')}>check domain</button>*/}
          </Title>
          <br></br>
          {/* <div className={styles.btnWrapper}>
            <a
              href="https://docs.google.com/forms/d/1mmPeD4C580LE2dkXj90AGADUjtBX1pF3KZZYsEgU6UE"
              target="_blank"
              rel="noreferrer"
              className={styles.submit}
            >
              <Button>Submit Your Projects</Button>
            </a>
          </div> */}
          <BtnContainer>
            {/* <Butn className={styles.btns}>
              {btnss.map((v: any, index: number) => (
                <button
                  key={index}
                  className={cn({ [styles.active]: v.active })}
                  onClick={() => onSelected(v.id, v.name)}
                >
                  {v.name}
                </button>
              ))}
            </Butn> */}
          </BtnContainer>
          <main>
            <div className={styles.stepDiv}>
              <Steps steps={steps}></Steps>
            </div>

            {/* {logos.length > 0 &&
              logos.map((v: any, index: number) => {
                return (
                  <Card
                    href={v.projecturl}
                    key={index}
                    img={v.logourl}
                    projectname={v.projectname}
                    category={v.category}
                  />
                );
              })} */}
          </main>
          <div className={styles.videoNft}>
            {/* <video
              width='100%'
              autoPlay
              loop
              muted
              playsInline
              src={videodata}
       
            ></video> */}
          </div>
        </Inner>
      </div>
      <div className={styles.footerBox}>
        {/* <img alt="" src={pet} className={styles.petImg} /> */}
        <Footer />
      </div>
    </LineaCard>
  );
}
const CardItem = styled.div`
  padding-top: 26px;
  box-sizing: border-box;
  width: 180px;
  height: 260px;
  background: #1d1d1d;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 1px solid #4b4b4b;
  position: relative;
  article {
    margin-top: 20px;
    font-size: 20px;
    font-family: 'GT-Walsheim-Regular-Trial';
    font-weight: bold;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  aside {
    font-size: 16px;
    font-family: 'GT-Walsheim-Regular-Trial';
    color: #ffffff;
    line-height: 25px;
    text-align: center;
  }
  @media screen and (max-width: 1180px) {
    padding-top: 20px;
    width: 141px;
    height: 203px;
    article {
      margin-top: 16px;
      font-size: 15px;
    }
    aside {
      font-size: 13px;
    }
  }
`;

const RoundSide = styled.div`
  margin: 0 auto;
  width: 136px;
  height: 136px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 106px;
    height: 106px;
  }
`;

const Logo = styled.img`
  width: 110px;
  height: 110px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }
`;
const RoundInner = styled.div`
  width: 110px;
  height: 110px;
  background: #000000;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }

  &:hover {
    animation: jelly 0.5s;
  }
  @keyframes jelly {
    0%,
    100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
`;

const Round = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background: #4b4b4b;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  @media screen and (max-width: 1180px) {
    width: 13px;
    height: 13px;
    right: -6px;
    top: -6px;
  }
`;

interface CardProps {
  img: string;
  projectname: string;
  category: string;
  href: string;
}
function Card(props: CardProps) {
  const { img, projectname, category, href } = props;
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <CardItem>
        <Round></Round>
        <RoundSide>
          <RoundInner>
            <Logo src={img} />
          </RoundInner>
        </RoundSide>
        <article>{projectname}</article>
        <aside>{category}</aside>
      </CardItem>
    </a>
  );
}
